<section>
  <div class="contact-us-form-wrapper">
    <div class="form-wrapper">
      <div class="form-title">Contact Us!</div>
      <div class="form-subtext">(386) 872-2010</div>
      <div class="form-subtext">lasbrasascatering@gmail.com</div>
      <div class="form-subtext">¡Hablamos español!</div>
<!--      <button (click)="openModal(contactUsFormSentNotice)">button</button>-->
      <form id="contact-us-form" [formGroup]="contactUsForm" (submit)="submitForm()">
        <div class="mb-3">
          <label for="name" class="form-label">FULL NAME</label>
          <input type="text" class="form-control custom-form-control" id="name" formControlName="name">
        </div>
        <div class="mb-3">
          <label for="emailAddress" class="form-label">EMAIL ADDRESS</label>
          <input type="email" class="form-control custom-form-control" id="emailAddress" formControlName="emailAddress"
                 required>
        </div>
        <div class="mb-3">
          <label for="message" class="form-label">MESSAGE<span style="text-align: center">*</span></label>
          <textarea class="form-control no-focus-border" id="message" rows="7" placeholder="Type your message here..."
          formControlName="message" required>
          </textarea>
        </div>
      </form>
      <div class="submit-form-button">
        <button class="btn btn-dark" type="submit" [disabled]="!contactUsForm.valid" (click)="submitForm()">
          Send Message</button>
      </div>
    </div>
  </div>
  <ng-template #contactUsFormSentNotice>
    <div class="modal-header request-a-booking-modal-header">
      <h4 class="modal-title pull-left white-font" *ngIf="successfulResponse">Thank you!</h4>
      <h4 class="modal-title pull-left white-font" *ngIf="!successfulResponse">Oops!</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body response-modal-padding">
      <p *ngIf="successfulResponse">
        Thank you for your message. We will get back to you as soon as possible!
        <br>
        <br>
        In the meantime, feel free to browse around or give us a call at +1 (386) 872-2010.
        <br>
        <br>
        -Las Brasas Team
      </p>
      <p *ngIf="!successfulResponse">
        Sorry for the inconvenience, looks like there was a little problem with our server. Refresh your page and try
        again! You can always give us a call at +1 (386) 872-2010. Thank you!
        <br>
        <br>
        -Las Brasas Team
      </p>
    </div>
  </ng-template>
</section>
