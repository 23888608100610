import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../shared/constants/app.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.css']
})
export class ContactUsFormComponent implements OnInit {

  contactUsForm: FormGroup;
  name: string;
  emailAddress: string;
  message: string;
  modalRef: BsModalRef;
  successfulResponse: boolean;
  @ViewChild('contactUsFormSentNotice') contactUsFormSentNoticeDiv: TemplateRef<any>;

  constructor(private httpClient: HttpClient, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.createContactUsForm();
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  createContactUsForm(): void {

    const option = {value: '', disabled: false};

    this.contactUsForm = new FormGroup({
      name: new FormControl(option, [Validators.pattern(AppConstants.NAME_REGEX)]),
      emailAddress: new FormControl(option, [Validators.required, Validators.pattern(AppConstants.EMAIL_ADDRESS)]),
      message: new FormControl(option, [Validators.required])
    });
  }

  submitForm(): void {
    this.emailAddress = this.contactUsForm.controls.emailAddress.value;
    this.name = this.contactUsForm.controls.name.value;
    this.message = this.contactUsForm.controls.message.value;

    // make API call to trigger Lambda function to send email
    this.httpClient.post<any>('https://9wwuvr6a47.execute-api.us-east-1.amazonaws.com/default/las-brasas-contact-form', {
      name: this.contactUsForm.controls.name.value, email: this.contactUsForm.controls.emailAddress.value, message:
      this.contactUsForm.controls.message.value}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    }).subscribe(data => {

      /* if success */
      if (data.message === 'Success') {

        this.contactUsForm.reset();
        this.successfulResponse = true;
        this.openModal(this.contactUsFormSentNoticeDiv);
      }
      /* there was an error */
    }, (error => {
      console.log('error: ', error);
      if (error.message === '404') {
        console.log('Page not found');
      }
      this.contactUsForm.reset();
      this.successfulResponse = false;
      this.openModal(this.contactUsFormSentNoticeDiv);
    }));
  }
}
// #f1ecee
