<section id="homeSection">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="assets/images/Las%20Brasas%20logo-round.png"
                                            alt="las-brasas-catering-logo" class="site-logo responsive"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="bebas-neue-white-font collapse navbar-collapse main-page-contact-details">
        <p class="phone-number">(386) 872-2010</p>
        <p class="email">Ormond Beach, FL</p>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(services)">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(aboutUs)">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scroll(contactUs)">Contact us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="logoVideo">
    <video class="library" preload="auto" loop="loop" autoplay="autoplay" [muted]="'muted'">
      <source src="assets/lasBrasasLogoVid.MP4" type='video/mp4'>
    </video>
  </div>
  <div class="container-wrapper">
    <div class="container" data-aos="fade-down">
      <div class="box">
        <div>
          <div class="cow-box">
            <img src="assets/icons/cow-145928.svg" alt="cow">
          </div>
          <div class="divider-line"></div>
          <div class="pig-box">
            <img src="assets/icons/pig.svg" alt="pig">
          </div>
          <div class="sheep-box">
            <img src="assets/icons/sheep-1801296.svg" alt="lamb">
          </div>
          <div class="divider-line"></div>
          <div class="chicken-box">
            <img src="assets/icons/hen-white.svg" alt="chicken">
          </div>
        </div>
        <div class="bebas-neue-white-font title-and-subtitle">
          <h1 class="title">Las Brasas Catering & Events</h1>
          <p class="neuzeit-font subtitle">
            Welcome to our local catering and food truck business, where our Uruguayan chef serves up mouth-watering
            parrilla and fusion cuisine dishes for weddings, corporate events, and more. As a family-owned business,
            we pride ourselves on exceptional service and unforgettable experiences. If you're looking for delicious
            food in Ormond Beach, look no further than our award-winning grilling contests and food truck.
            Contact us today to learn more about our catering services and upcoming events.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section #services>
  <div class="welcome-wrapper">
    <h1 class="bebas-neue-black-font welcome-title">Catering & Events</h1>
    <h2 class="neuzeit-font welcome-subtitle">
      We take pride in being a family-owned business, and we treat our customers like family too. Our team is dedicated
      to providing top-notch service and creating a memorable experience for every event we cater. Whether you're
      planning a wedding, corporate event, or simply looking for a delicious meal, we're here to help. Contact us today
      to learn more about our catering services. We look forward to serving you!
    </h2>

    <div class="events-boxes-wrapper">
      <div class="event-box weddings-box">
        <div class="box-container">
          <div class="frosted-layer"
               data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/white-heart.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">Weddings</h3>
              <p class="gurmukhi-font">Creating memories with you on your special day.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="event-box birthdays-box">
        <div class="box-container">
          <div class="frosted-layer" data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/gift-box.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">Birthdays</h3>
              <p class="gurmukhi-font">Celebrate life, celebrate with us!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="event-box dinner-parties-box">
        <div class="box-container">
          <div class="frosted-layer" data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/dishware.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">Dinner Parties</h3>
              <p class="gurmukhi-font">Bring on the food party.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="event-box quinceanera-box">
        <div class="box-container">
          <div class="frosted-layer" data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/quinceañera.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">Quinceañera</h3>
              <p class="gurmukhi-font">Celebra sus quince con nuestra ayuda.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="event-box private-events">
        <div class="box-container">
          <div class="frosted-layer" data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/martini.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">Private Events</h3>
              <p class="gurmukhi-font">Making occasions more flavorful.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="event-box more-box">
        <div class="box-container">
          <div class="frosted-layer" data-aos="fade-up">
            <div>
              <div>
                <img src="assets/icons/plus.svg" alt="white-heart-icon" class="event-boxes-icon">
              </div>
              <h3 class="gurmukhi-font">...and more!</h3>
              <p class="gurmukhi-font">Whatever your event may be, no matter how big or small, we feed your dreams!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2">
        </button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3">
        </button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4">
        </button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5">
        </button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6">
        </button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="13000">
          <div class="event-box weddings-box">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/white-heart.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">Weddings</h3>
                  <p class="gurmukhi-font">Creating memories with you on your special day.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <div class="event-box birthdays-box">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/gift-box.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">Birthdays</h3>
                  <p class="gurmukhi-font">Celebrate life, celebrate with us!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <div class="event-box dinner-parties-box">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/dishware.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">Dinner Parties</h3>
                  <p class="gurmukhi-font">Bring on the food party.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <div class="event-box quinceanera-box">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/quinceañera.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">Quinceañera</h3>
                  <p class="gurmukhi-font">Celebra sus quince con nuestra ayuda.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <div class="event-box private-events">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/martini.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">Private Events</h3>
                  <p class="gurmukhi-font">Making occasions more flavorful.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <div class="event-box more-box">
            <div class="box-container">
              <div class="frosted-layer">
                <div>
                  <div>
                    <img src="assets/icons/plus.svg" alt="white-heart-icon" class="event-boxes-icon">
                  </div>
                  <h3 class="gurmukhi-font">...and more!</h3>
                  <p class="gurmukhi-font">Whatever your event may be, no matter how big or small, we feed your dreams!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</section>
<section>
  <div class="book-tasting-wrapper-div">
    <div class="book-tasting-dark-wrapper">
      <div>
        <h3 class="bebas-neue-white-font book-tasting-h3" data-aos="fade-down">Book an Event</h3>
        <p class="neuzeit-font book-tasting-p" data-aos="slide-right">Give us more details about your event,
          this info will help us make a quote.</p>
        <button type="button" class="btn btn-light bebas-neue-black-font make-request-button"
        (click)="openModal(makeARequestForm)" data-aos="fade-up">Make a Request</button>
        <ng-template #makeARequestForm>
          <div class="modal-header request-a-booking-modal-header">
            <h4 class="modal-title pull-left white-font">Request a booking</h4>
            <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <form id="request-booking-form" [formGroup]="requestBookingForm" (submit)="submitRequestBookingForm()">
                <div class="form-group center-text-align">
                  <label for="nameOfEvent">Name of Event</label>
                  <input type="text" class="form-control" id="nameOfEvent" formControlName="eventName"
                         placeholder="i.e. 'Jane & John's wedding">
                </div>
                <div class="form-group center-text-align">
                  <label for="nameOfBooker">Name of booker<span class="required-star">*</span></label>
                  <input type="text" class="form-control" id="nameOfBooker" formControlName="nameOfBooker"
                         placeholder="Person booking this event...">
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Email<span class="required-star">*</span></label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="Email"
                    formControlName="bookerEmailAddress">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="bookerPhoneNumber">Phone Number<span class="required-star">*</span></label>
                    <input type="text" class="form-control" id="bookerPhoneNumber" placeholder="Phone Number"
                    formControlName="bookerPhoneNumber">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="mr-sm-2" for="inlineFormCustomSelect">Type of Event<span class="required-star">*
                    </span></label>
                    <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" formControlName="eventType">
                      <option selected>Choose...</option>
                      <option value="Wedding">Wedding</option>
                      <option value="Birthday">Birthday</option>
                      <option value="Dinner">Dinner party</option>
                      <option value="Quinceañera">Quinceañera</option>
                      <option value="Graduation">Graduation</option>
                      <option value="Business">Business Meeting</option>
                      <option value="Baby">Baby Shower</option>
                      <option value="Wedding">Wedding Shower</option>
                      <option value="Holiday">Holiday party</option>
                      <option value="bbq">Barbecue</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-xs-12 col-12 col-md-6 form-group mb-3">
                    <label for="dateOfEvent">Date of Event<span class="required-star">*</span></label>
                    <input type="date" placeholder="Date of event" class="form-control" bsDatepicker id="dateOfEvent"
                    [bsConfig]="{ isAnimated: true, containerClass: 'theme-blue' }" formControlName="eventDate"
                    required>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="numberOfGuests">Number of Guests<span class="required-star">*</span></label>
                    <input type="number" id="numberOfGuests" class="form-control" formControlName="numberOfGuests">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="locationOfEvent">Location<span class="required-star">*</span></label>
                    <input type="text" class="form-control" id="locationOfEvent" placeholder="City/Name of Venue"
                    formControlName="eventLocation">
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Tell us more about your event!</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" formControlName="message">
                  </textarea>
                </div>
                <div class="send-button-wrapper">
                  <button type="submit" class="btn btn-outline-dark">SEND</button>
                </div>
                <br>
                <span class="small-print"><span class="required-star">*</span>Required</span><br>
                <span class="small-print"><span class="required-star">**</span>keep in mind this is only a request to
                  book, not an actual booking. After we speak directly we will book your event!</span>
              </form>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="clients-favorites-wrapper">
    <div class="title-and-about">
      <h1 class="bebas-neue-black-font-bold">Clients' Favorites</h1>
      <p class="neuzeit-font" data-aos="zoom-in">
<!--        If you're anything like us at Las Brasas, whenever you go to a new eatery, you may ask, "What's the most popular-->
<!--        item on the menu?" That is why we've put together a list of our most requested items on the menu. This does not-->
<!--        mean we can't or won't go outside the favorites. At Las Brasas, we strive on implementing an all-round catering-->
<!--        service for every occasion, function and taste.-->

        At Las Brasas, we offer a diverse menu that showcases some of our favorite dishes from around the world.
        For those craving a taste of Mexico, our tacos are a must-try, with options ranging from traditional carne asada
        to creative vegetarian fillings. Our barbecue options feature tender, slow-cooked meats with mouth-watering
        sauces that are perfect for any meat lover. And for those looking for a taste of Spain, our paella is a standout
        dish, featuring perfectly cooked rice and a variety of fresh seafood and meats. Come visit us and try all of our
        favorite menu items for yourself!
      </p>
    </div>
    <div class="gallery-wrapper">
      <div class="row">
        <div class="column">
          <div class="favorites-description">
            <h4>BBQ</h4>
            <p>BBQ items are classic and an easy crowd-pleaser.</p>
          </div>
          <div class="steak-img favorites-img" data-aos="flip-right"></div>
        </div>
        <div class="column">
          <div class="pasta-img favorites-img" data-aos="flip-right"></div>
          <div class="favorites-description">
            <h4>Pasta</h4>
            <p>An all time favorite, with your favorite sauces. Why choose one?! Have them all!</p>
          </div>
        </div>
        <div class="column">
          <div class="favorites-description">
            <h4>Paella</h4>
            <p>A delicious Spanish dish that will leave your guests wanting more.</p>
          </div>
          <div class="paella-img favorites-img" data-aos="flip-right"></div>
        </div>
        <div class="column">
          <div class="tacos-img favorites-img" data-aos="flip-right"></div>
          <div class="favorites-description">
            <h4>Tacos</h4>
            <p>Who doesn't love tacos? With options for meat-lovers and vegetarians alike!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bebas-neue-black-font-bold menu-list">Main Entrees  ❖ Appetizers ❖ Desserts ❖ Finger Food
    </div>
  </div>
</section>
<section>
  <div id="reviews-div-wrapper">
    <div class="image-gallery row" id="bigger-screen-sizes">
      <div class="column-2">
        <img src="assets/images/bbq2.png" alt="bbq">
      </div>
      <div class="column-2">
        <img src="assets/images/pasta2.png" alt="pasta">
      </div>
      <div class="column-2">
        <img src="assets/images/sliders.png" alt="sliders">
      </div>
      <div class="column-2">
        <img src="assets/images/empanadas.png" alt="empanadas">
      </div>
      <div class="column-2">
        <img src="assets/images/paella2.png" alt="paella">
      </div>
    </div>
    <div id="reviewsCarouselWithImages" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/images/bbq2.png" class="d-block w-100" alt="bbq">
          <div class="carousel-caption d-md-block standalone-gallery-dark-wrapper">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/pasta2.png" class="d-block w-100" alt="pasta">
          <div class="carousel-caption d-md-block standalone-gallery-dark-wrapper">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore m
              agna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/sliders.png" class="d-block w-100" alt="sliders">
          <div class="carousel-caption d-md-block standalone-gallery-dark-wrapper">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/empanadas.png" class="d-block w-100" alt="empanadas">
          <div class="carousel-caption d-md-block standalone-gallery-dark-wrapper">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/paella2.png" class="d-block w-100" alt="paella">
          <div class="carousel-caption d-md-block standalone-gallery-dark-wrapper">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#reviewsCarouselWithImages" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div class="bigger-screen-sizes">
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                  aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2">
          </button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3">
          </button>
        </div>
        <div class="carousel-inner">
          <div class="review-carousel-item carousel-item active">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
          <div class="review-carousel-item carousel-item">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
          <div class="review-carousel-item carousel-item">
            <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</q>
            <br>
            <br>
            <span>-Jane Doe</span>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
</section>
<section #aboutUs>
  <div class="about-us-wrapper-section" data-aos="fade">
    <div class="meet-the-chef-wrapper responsive" style="background-image: url('assets/images/Group 132.svg')">
      <div class="about-chef-text-wrapper">
        <h1>Meet the Chef!</h1>
        <p>
          Meet Gabriel Martinez, a talented chef with roots in beautiful Uruguay, where his passion for food was
          cultivated by his parents and grandparents. With a long line of cooks and foodies in his family, Gabriel
          learned all the culinary essentials at UTU before launching his career in the kitchen. Specializing in the art
          of the barbecue, Gabriel has become an expert in Asado, Uruguay's staple grilling technique, which is served
          in parrillas at restaurants and food carts across the country. With over 20 years of experience catering
          events and hosting parties, Gabriel has won numerous titles at King of the Grill competitions, showcasing his
          impressive skills in the kitchen. As the founder of Las Brasas Catering & Events and Las Brasas Grill, Gabriel
          runs a successful family-owned business alongside his daughter, providing exceptional food and customer
          service to the local community of Ormond Beach.
<!--          Gabriel Martinez was born and raised in beautiful Uruguay, where he attended UTU and learned all the culinary-->
<!--          essentials. But his passion for food and cooking had already been cultivated by his parents and grandparents.-->
<!--          Gabriel comes from a long line of cooks and passionate foodies. His mother was a longtime cook for a private-->
<!--          Catholic School in Uruguay. Hosting parties for family and friends was a common occurrence in his-->
<!--          childhood home. His mother's dishes varied from typical Uruguayan dishes such as empanadas to delicate French-->
<!--          cuisine, Spanish paellas and bbq items. Gabriel has taken to the bbq side for he is a meat lover and an expert-->
<!--          at it. Bbq is a staple in Uruguay. Asado, as Uruguayans call it, is served in a parrilla(grill), at-->
<!--          restaurants and food carts all over the country and at home as a family or social occasion.-->
        </p>
        <p>
          Beyond his culinary talents, Gabriel is a community-driven chef who supports small businesses
          in the area and even feeds police officers during hurricanes. When he's not in the kitchen, he enjoys watching
          soccer and spending time with his loved ones. With every dish he creates, Gabriel's passion, hard work, and
          dedication to his craft shines through.
<!--          With over 20 years of experience, Gabriel has catered many types of events and has hosted many parties-->
<!--          himself. He has won a title at every King of the Grill competition he has participated in and continues to-->
<!--          improve his long list of skills in the kitchen!-->
        </p>
        <p>
<!--          Las Brasas Catering & Events and Las Brasas Grill is a family owned business. This father & daughter duo work-->
<!--          together as a team to provide excellent food and customer service. Gabriel plays a key role in the community-->
<!--          of Ormond Beach. During hurricanes, he feeds the police officers who work long shifts to assure the safety-->
<!--          of our community. He also supports other small businesses in the area and enjoys watching soccer in his free-->
<!--          time. His dishes say a lot about this hard-working, passionate and community-driven chef!-->
        </p>
      </div>
    </div>
    <div class="chef-image">
      <img src="https://lasbrasascateringimages.s3.amazonaws.com/Tio.jpeg" alt="Gabriel Martinez" class="responsive"
           id="tio">
    </div>
  </div>
</section>
<section>
  <div id="king-of-the-grill-wrapper">
    <!-- View for screen sizes 575px and smaller -->
    <div class="carousel-view-kotg">
      <div>
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2">
            </button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="https://lasbrasascateringimages.s3.amazonaws.com/King+of+the+Grill.jpeg" class="d-block w-100"
                   alt="KOTG[[YEAR]]">
            </div>
            <div class="carousel-item">
              <img src="https://lasbrasascateringimages.s3.amazonaws.com/trophies2.jpeg" class="d-block w-100"
                   alt="KOTG[[YEAR]]">
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleControls2" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleControls2" data-bs-slide-to="1" aria-label="Slide 2">
            </button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="https://lasbrasascateringimages.s3.amazonaws.com/trophies.png" class="d-block w-100"
                   alt="trophies">
            </div>
            <div class="carousel-item">
              <img src="https://lasbrasascateringimages.s3.amazonaws.com/King+of+the+Grill+3.jpeg" class="d-block w-100"
                   alt="KOTG[[YEAR]]">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View for screen sizes 576px and bigger -->
    <div class="gallery-view-kotg">
      <div>
        <div class="king-of-the-grill-img-wrapper" data-aos="fade-down-right">
          <img src="https://lasbrasascateringimages.s3.amazonaws.com/King+of+the+Grill.jpeg" alt="KOTG[[YEAR]]"
               class="responsive">
        </div>
        <div class="king-of-the-grill-img-wrapper" data-aos="fade-down-left">
          <img src="https://lasbrasascateringimages.s3.amazonaws.com/trophies.png" alt="trophies" class="responsive">
        </div>
        <div class="king-of-the-grill-img-wrapper" data-aos="fade-up-right">
          <img src="https://lasbrasascateringimages.s3.amazonaws.com/trophies2.jpeg" alt="KOTG[[YEAR]]"
               class="responsive">
        </div>
        <div class="king-of-the-grill-img-wrapper" data-aos="fade-up-left">
          <img src="https://lasbrasascateringimages.s3.amazonaws.com/King+of+the+Grill+3.jpeg" alt="KOTG[[YEAR]]"
               class="responsive">
        </div>
      </div>
      <div class="centered-KOTG-logo">
        <img src="assets/images/KingOfTheGrillLogo.png" alt="King of the Grill Logo" class="centered-KOTG-logo-img">
      </div>
    </div>

    <div class="kotg-summary" data-aos="slide-left">
      <h1>King of the Grill Winner</h1>
      <div class="king-of-the-grill-logo">
        <img src="assets/images/KingOfTheGrillLogo.png" alt="King of the Grill Logo">
      </div>
      <p>King of The Grill is a bbq competition organized by the Chamber of Commerce in Ormond Beach, FL. This event
        consists of professional restaurants and amateur grillers, all competing for the title “King of the Grill.”
        Our chef Gabriel Martinez has competed every year since it began. We're listings his winnings below. It is an
        honor to have a master griller and chef such as Gabriel catering events for Las Brasas Grill.
<!--        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore-->
<!--        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo-->
<!--        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla-->
<!--        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id-->
<!--        est laborum.-->
      </p>
    </div>
    <div class="trophy-list-wrapper">
      <div>
        <img src="assets/icons/trophy.svg" alt="trophy">
      </div>
      <ul>
        <li>2013 <strong>1st</strong> Place- Amateur Griller</li>
        <li>2015 <strong>3rd</strong> Place- People's Choice</li>
        <li>2016 <strong>1st</strong> Place- Amateur Griller</li>
        <li>2017 <strong>2nd</strong> Place- Amateur Griller</li>
        <li>2018 <strong>3rd</strong> place- Amateur Griller</li>
      </ul>
    </div>
  </div>
</section>
<router-outlet #contactUs></router-outlet>
<section>
  <div class="statement-wrapper">
    <div class="pizza-slice-icon"><img alt="pizza slice" src="assets/icons/megaphone-outline.svg"></div>
    <div class="statement-title" data-aos="flip-left">Announcement!</div>
    <div class="statement-p">
      We're excited to announce a new project for Las Brasas Catering & Events. A food truck, Las Brasas Grill! The food
      truck will have most of your favorite bbq items. We will be announcing the times and locations we will  be serving
      from as we move around our beautiful town. We hope to see you there as we embark on this new adventure and thank
      you for your continued support!
    </div>
  </div>
</section>
<footer>
  <div class="footer-wrapper">
    <div class="footer-text footer-block">
      <h2 class="lighter-font-weight">HASHTAG YOUR POST WITH</h2>
      <H1 class="bold-font-weight">#LASBRASASCATERING</H1>
    </div>
    <div class="footer-text footer-block">
      <h2 class="lighter-font-weight">(386) 872-2010</h2>
      <h2 class="lighter-font-weight">lasbrasascatering@gmail.com</h2>
      <H1 class="bold-font-weight">ORMOND BEACH, FL</H1>
    </div>
    <div class="footer-social-media-wrapper footer-block">
      <div>
        <a href="https://www.facebook.com/Lasbrasascatering" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-facebook"></i>
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/lasbrasascateringandevents/" target="_blank" rel="noopener noreferrer">
          <i class="fa fa-instagram"></i>
        </a>
      </div>
    </div>
    <div class="footer-block">
      <img src="https://lasbrasascateringimages.s3.amazonaws.com/ormond-beach-map.png" alt="ormond-beach-fl"
           class="responsive">
    </div>
  </div>
</footer>
