import { Component, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from './shared/constants/app.constants';
import AOS from 'aos';

AOS.init({
  offset: 200,
  delay: 50,
  duration: 2000,
  easing: 'ease-in-out',
  mirror: 'true',
  once: false
  // data-aos-anchor-placement="top-center"
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  modalRef: BsModalRef;
  title = 'lasBrasasCatering';

  requestBookingForm: FormGroup;
  eventName: string;
  nameOfBooker: string;
  bookerEmailAddress: string;
  bookerPhoneNumber: string;
  eventType: string;
  eventDate: string;
  numberOfGuests: number;
  eventLocation: string;
  message: string;

  constructor(private modalService: BsModalService) {
    this.createRequestBookingForm();
  }

  scroll(element: HTMLElement): void {
    element.scrollIntoView();
  }

  createRequestBookingForm(): void {

    const option = { value: '', disabled: false };

    this.requestBookingForm = new FormGroup({
      eventName: new FormControl(option),
      nameOfBooker: new FormControl(option, [Validators.pattern(AppConstants.NAME_REGEX)]),
      bookerEmailAddress: new FormControl(option, Validators.pattern(AppConstants.EMAIL_ADDRESS)),
      bookerPhoneNumber: new FormControl(option, Validators.pattern(AppConstants.PHONE_NUMBER)),
      eventType: new FormControl(option, Validators.required),
      eventDate: new FormControl(option, Validators.required),
      numberOfGuests: new FormControl(option, Validators.required),
      eventLocation: new FormControl(option),
      message: new FormControl(option)
    });
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  submitRequestBookingForm(): void {

    this.eventName = this.requestBookingForm.controls.eventName.value;
    // console.log('this.eventName: ', this.eventName);
    this.nameOfBooker = this.requestBookingForm.controls.nameOfBooker.value;
    // console.log('this.nameOfBooker: ', this.nameOfBooker);
    this.bookerEmailAddress = this.requestBookingForm.controls.bookerEmailAddress.value;
    // console.log('this.bookerEmailAddress: ', this.bookerEmailAddress);
    this.bookerPhoneNumber = this.requestBookingForm.controls.bookerPhoneNumber.value;
    // console.log('this.bookerPhoneNumber: ', this.bookerPhoneNumber);
    this.eventType = this.requestBookingForm.controls.eventType.value;
    // console.log('this.eventType: ', this.eventType);
    this.eventDate = this.requestBookingForm.controls.eventDate.value;
    // console.log('this.eventDate: ', this.eventDate);
    this.numberOfGuests = this.requestBookingForm.controls.numberOfGuests.value;
    // console.log('this.numberOfGuests: ', this.numberOfGuests);
    this.eventLocation = this.requestBookingForm.controls.eventLocation.value;
    // console.log('this.eventLocation: ', this.eventLocation);
    this.message = this.requestBookingForm.controls.message.value;
    // console.log('this.message: ', this.message);
  }
}
